import React from 'react';

const Research = () => {
    return (
        <section id="research" className="research-section">
            <h2>科研项目</h2>
            <div className="research-subsection">
                <h3>论文</h3>
                <p>这里是论文内容。</p>
            </div>
            <div className="research-subsection">
                <h3>项目</h3>
                <p>这里是项目内容。</p>
            </div>
            <div className="research-subsection">
                <h3>实验室</h3>
                <p>这里是实验室内容。</p>
            </div>
        </section>
    );
};

export default Research;
