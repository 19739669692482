import React from 'react';

const Competitions = () => {
    return (
        <section id="competitions" className="competitions-section">
            <h2>学科竞赛</h2>
            <p>这里是学科竞赛的内容。</p>
        </section>
    );
};

export default Competitions;
