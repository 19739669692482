import React from 'react';

const Education = () => {
    return (
        <section id="education" className="education-section">
            <h2>学习经历</h2>
            <p>2022年从成都来到重庆，到重庆邮电大学学习。</p>
        </section>
    );
};

export default Education;
